<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">

        <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="网站名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请网站名称..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="网站状态" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
            <a-select v-model="form.status" placeholder="请选择状态...">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="active">
                正常
              </a-select-option>
              <a-select-option value="forbidden">
                异常
              </a-select-option>

            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="Whois状态" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="whois_status">
            <a-select v-model="form.whois_status" placeholder="请选择状态...">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="enable">
                正常
              </a-select-option>
              <a-select-option value="disable">
                异常
              </a-select-option>

            </a-select>
          </a-form-model-item>
        </a-col>
       

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加
      </a-button>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >

        <!-- 网站状态字段  -->
        <template slot="status" slot-scope="data,record">
          <a-tag :color="statusColor[record.status]" style="margin-right:0">
            {{record.status|statusFilter}}
          </a-tag>
        </template>

        <!-- Whois状态字段  -->
        <template slot="whois_status" slot-scope="data,record">
          <a-tag :color="whoisStatusColor[record.whois_status]" style="margin-right:0">
            {{record.whois_status|whoisStatusFilter}}
          </a-tag>
        </template>

        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span >
                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>
                <a-popconfirm title="是否删除此域名?" @confirm="() => onDelete(data)">
                  <a-button size="small" type="danger">删除</a-button type="primary" >
                </a-popconfirm>
              </span>

        </template>


      </a-table>
    </div>


    <!-- 修改帐号信息模态框 -->
    <a-modal
      :maskClosable="false"
      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="saveSites"
      @cancel="cancelSites"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="sites"
        :model="sitesForm"
        :rules="sitesFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >

      <a-form-model-item label="域名" prop="domain">
        <a-input v-model="sitesForm.domain" placeholder="请输入域名"/>
      </a-form-model-item>
      <a-form-model-item label="用户"  prop="user_id" v-if="this.token.role == 'super'" >
        <a-select v-model="sitesForm.user_id" placeholder="请选择用户..." :options='userList'>

        </a-select>
      </a-form-model-item>

      </a-form-model>
    </a-modal>



  </div>
</template>
<script>
import {getSitesList,addSites,deleteSites,editSites} from "@/axios/sites.js"
import {getAllUser} from "@/axios/user.js"
import {getCookie} from '@/static/js/cookie';
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '网站域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '网站状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status', },
  },

  {
    align:'center',
    title: 'Whois状态',
    dataIndex: 'whois_status',
    scopedSlots: { customRender: 'whois_status', },
  },

  {
    align:'center',
    title: '创建时间',
    dataIndex: 'created_at',
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {

  filters: {

    statusFilter(status) {
      const statusMap = {
        'active': '正常',
        'forbidden': '异常',
      }
      return statusMap[status]
    },
    whoisStatusFilter(status) {
      const statusMap = {
        'enable': '正常',
        'disable': '异常',
      }
      return statusMap[status]
    },
  },
  mixins: [myMixin],
  data() {

    return {
      form: {
        page_size: 10,
        page:1,
        user_id:'',
        status:'',
        whois_status:'',

      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      sitesForm:{
        id:'',
        domain:'',
        user_id:'',
      },
      sitesFormRules: {

        domain: [
          { required: true, message: '请输入网站名称', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: '请选择客服', trigger: 'change' },
        ],

      },
      status: [
        {
          value: 'active',
          label: '正常'
        },
        {
          value: 'forbidden',
          label: '异常'
        },
      ],
      whois_status: [
        {
          value: 'active',
          label: '正常'
        },
        {
          value: 'forbidden',
          label: '异常'
        },
      ],
      statusColor: {
        "active": 'green',
        "forbidden": '#ff0000',
      },
      whoisStatusColor:{
        "enable": 'green',
        "disable": '#ff0084',
      },
      title:'添加网站',
      userList:[],
      token: {},
    }

  },
  mounted() {
    let that=this;
    this.token = JSON.parse(getCookie("token"));
    this.form.user_id  = this.token.user_id;
    if (this.token.role === 'super') {

      getAllUser({ show_super: 1 }).then((data) => {
        this.userList = data.data.map((item) => {
          return {
            value: item.id,
            label: item.username
          }
        })
      })

    }
    this.fetch();
  },
  methods:{


      async fetch (params = {}) {
        this.loading = true;

        let data=await getSitesList({...params,...this.form})
        const pagination = { ...this.pagination };
          pagination.total = data.data.total;

          this.data = data.data.list;
          this.pagination = pagination;
          console.log(data,this.pagination,pagination,'data')
    
        this.loading = false;
        
      },

      //添加商户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加网站'
      },

      //删除数据
      async onDelete(data){

        let resule=await deleteSites({id:data.id});
          if(resule.code === 200){
            this.$message.success(resule.msg);
            this.fetch()
          }else{
            this.$message.error(resule.msg);
          }
      },

      //编辑保存账户数据
      onEdit(data){
        this.title='编辑网站'
        this.editModalVisible=true
        this.$nextTick(() => { // 不用nextTick的话，清空数据时会有问题
          for (let key in this.sitesForm) {
            this.sitesForm[key] = data[key];
          }
          //console.log(this.sitesForm,this.sitesForm.bw_switch,data.bw_switch,'点编辑时的信息')
        });


      },
      
      //更新支付方式
      saveSites(){
        this.$refs.sites.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title === '添加网站'){
            resule=await addSites(this.sitesForm);
          }else{
            resule=await editSites({config_id:this.sitesForm.id,...this.sitesForm,});
          }
          

          if(resule.code === 200){
            this.$message.success(resule.msg);
            this.editModalVisible = false;
            this.$refs.sites.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.msg);
          }
          this.confirmLoading = false;
        } else {
          return false;
        }
      });

      },

      cancelSites(){
        this.$refs.sites.resetFields();
        console.log(this.sitesForm,'sitesForm')
        this.editModalVisible = false;
      },


     
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
::v-deep .ant-table-bordered .ant-table-thead > tr > th{    word-break: inherit;}
::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
}
</style>
