
import {get,post} from "@/axios";



export function getSitesList(data) {
  return get('/api/sites/list',data);

}
export function addSites(data) {
  return post('/api/sites/add',data);

}
export function deleteSites(data) {
  return post('/api/sites/delete',data);

}

export function editSites(data) {
  return post('/api/sites/edit',data);

}
export function getSitesAll(data) {
  return get('/api/sites/all',data);

}



